import dynamicAPI from './dynamicAPI';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useMessage } from '../context/AdminMessageContext';

const url = '/api/auth/profile';
const queryKey = 'profiles';

export default function useProfilesHook(props) {
    const { page = 1, q = '', limit = 25 } = props;
    const queryClient = useQueryClient();
    const { setSuccessMessage, setErrorMessage } = useMessage(); // Use the message context

    const getProfile = useQuery(
        [queryKey, page, limit],
        async () => {
            const response = await dynamicAPI('get', `${url}?page=${page}&q=${q}&limit=${limit}`);
            if (response.error) {
                const errorMessage = response.error || 'An error occurred while fetching the profile.';
                setErrorMessage(errorMessage);
            } else {
                return response;
            }
        },
        {
            retry: 0,
            staleTime: 1000 * 15,
        }
    );

    const getUserProfiles = useQuery(
        ['userProfiles', page, q], // Use a more specific query key
        async () =>
            await dynamicAPI(
                'get',
                `${url}/user-profiles?page=${page}&q=${q}&limit=${limit}`,
                {}
            ),
        {
            retry: 0,
            // refetchOnWindowFocus: false, // Disable unnecessary refetching on window focus
            keepPreviousData: true, // Keep the previous data until the new data is fetched
        }
    );

    const postProfile = useMutation(
        async (obj) => await dynamicAPI('post', `${url}`, obj),
        {
            retry: 0,
            onSuccess: (data) => {
                if (data.isSuccess) {
                    setSuccessMessage('Profile Updated successfully.');
                    queryClient.invalidateQueries([queryKey]);
                } else {
                    const errorMessage = data.error || 'Failed to create profile.';
                    setErrorMessage(errorMessage);
                }
            },
            onError: (error) => {
                const errorMessage = error?.error || 'An error occurred while creating the profile.';
                setErrorMessage(errorMessage);
            }
        }
    );

    return { getProfile, getUserProfiles, postProfile };
}
