import {Routes, Route, useNavigate, Link} from 'react-router-dom';
import RequireAuth from './components/shared/RequireAuth';
import ForgotPassword from './screens/admin/ForgotPassword';
import Home from './screens/admin/Home';
import Login from './screens/admin/Login';
import ResetPassword from './screens/admin/ResetPassword';
import Profile from './screens/admin/Profile';
import ClientPermissions from './screens/admin/auth/ClientPermissions';
import Permissions from './screens/admin/auth/Permissions';
import Roles from './screens/admin/auth/Roles';
import UserRoles from './screens/admin/auth/UserRoles';
import UserProfiles from './screens/admin/auth/UserProfiles';
import Users from './screens/admin/auth/Users';
import Spaces from './screens/admin/auth/Spaces';
import Events from './screens/admin/auth/Events';
import UserSpaces from './screens/admin/auth/UserSpaces';
import NotFound from './screens/admin/NotFound';

import {AdminLayout} from './components';
import React from "react";
import {ErrorBoundary} from "react-error-boundary";
import {Button} from "@mui/material";
import {AuthProvider} from './context/AuthProvider'
import {AdminMessageProvider} from './context/AdminMessageContext';

const Admin = () => {
    const navigate = useNavigate();


    return (
        <>
            <ErrorBoundary
                FallbackComponent={() => (
                    <ErrorFallback goBack={() => navigate(-1)}/>
                )}
                onError={console.error}
            >
                <AdminMessageProvider>

                    <AuthProvider>
                        <AdminLayout showAdminNavigation={true}>
                            <Routes>
                                {/* OPEN ROUTES*/}
                                <Route path='/' element={<Home/>}/>
                                <Route path='/auth/login' element={<Login/>}/>
                                <Route path='/auth/forgot-password' element={<ForgotPassword/>}/>
                                <Route path='/auth/reset-password/:restToken' element={<ResetPassword/>}/>

                                {/* AUTH ROUTES*/}
                                <Route element={<RequireAuth/>}>
                                    {/*<Route path='/' element={<Home/>}/>*/}
                                    <Route path='/auth/account/profile' element={<Profile/>}/>
                                    <Route path='/auth/client-permissions' element={<ClientPermissions/>}/>
                                    <Route path='/auth/permissions' element={<Permissions/>}/>
                                    <Route path='/auth/roles' element={<Roles/>}/>
                                    <Route path='/auth/user-roles' element={<UserRoles/>}/>
                                    <Route path='/auth/user-profiles' element={<UserProfiles/>}/>
                                    <Route path='/auth/user-spaces' element={<UserSpaces/>}/>
                                    <Route path='/auth/users' element={<Users/>}/>
                                    <Route path='/auth/spaces' element={<Spaces/>}/>
                                    <Route path='/auth/events' element={<Events/>}/>
                                    {/*<Route path='/auth/products' element={<Products/>}/>*/}
                                </Route>

                                <Route path="*" element={<NotFound />} />
                            </Routes>
                        </AdminLayout>
                    </AuthProvider>
                </AdminMessageProvider>
            </ErrorBoundary>
        </>
    )
}

function ErrorFallback({error, goBack}) {
    const errorMessage = error && error.message ? error.message : "Unknown error";

    const clearLocalStorage = () => {
        localStorage.removeItem('userInfo');
        localStorage.removeItem('userRole');
        goBack(); // Trigger goBack after clearing local storage
    };


    return (
        <AdminLayout showAdminNavigation={false}>

            <div className="main-user-error-message-block">
                <div className="user-error-message-block">
                    <p>Something went wrong 😭</p>
                    <span>Error: {errorMessage}</span>
                    <p>-</p>
                    <p>
                        <Link to={"/"} className="shop_go_to_frontend_link">
                            GO TO FRONTEND
                        </Link>
                    </p>
                    <p>
                        <Button onClick={goBack}>Go Back</Button>
                    </p>
                    <p>
                        <Button onClick={clearLocalStorage}>
                            Clear Local Storage and Go Back
                        </Button>
                    </p>
                </div>
            </div>
        </AdminLayout>

    );
}

export default Admin
