import dynamicAPI from './dynamicAPI'
import {useQuery, useMutation, useQueryClient} from 'react-query'
import {useMessage} from "../context/AdminMessageContext";

const url = '/api/auth/userSpaces'

const queryKey = 'userSpaces'

export default function useUserSpacesHook(props) {
    const {page = 1, id, q = '', limit = 25} = props
    const queryClient = useQueryClient()
    const { setSuccessMessage, setErrorMessage } = useMessage(); // Use the message context

    const getUserSpaces = useQuery(
        [queryKey, page, limit],
        async () => {
            const response = await dynamicAPI('get', `${url}?page=${page}&q=${q}&limit=${limit}`);
            if (response.error) {
                const errorMessage = response.error || 'An error occurred while fetching the userSpaces.';
                setErrorMessage(errorMessage);
            } else {
                return response;
            }
        },
        {
            retry: 0,
            staleTime: 1000 * 15,
        }
    )

    const getUserSpaceById = useQuery(
        [queryKey, id],
        async () => {
            const response = await dynamicAPI('get', `${url}/${id}`);
            if (response.error) {
                const errorMessage = response.error || 'An error occurred while fetching the userSpace.';
                setErrorMessage(errorMessage);
            } else {
                return response;
            }
        },
        { retry: 0, enabled: !!id }
    )

    const updateUserSpace = useMutation(
        async (obj) => await dynamicAPI('put', `${url}/${obj._id}`, obj),
        {
            retry: 0,
            onSuccess: (data) => {
                if (data.isSuccess) {
                    setSuccessMessage('UserSpace updated successfully.');
                    queryClient.invalidateQueries([queryKey]);
                } else {
                    const errorMessage = data.error || 'Failed to update UserSpace.';
                    setErrorMessage(errorMessage);
                }
            },
            onError: (error) => {
                const errorMessage = error?.error || 'An error occurred while updating the UserSpace.';
                setErrorMessage(errorMessage);
            }
        }
    );

    const deleteUserSpace = useMutation(
        async (id) => await dynamicAPI('delete', `${url}/${id}`, {}),
        {
            retry: 0,
            onSuccess: (data) => {
                if (data.isSuccess) {
                    setSuccessMessage('UserSpace deleted successfully.');
                    queryClient.invalidateQueries([queryKey]);
                } else {
                    const errorMessage = data.error || 'Failed to delete UserSpace.';
                    setErrorMessage(errorMessage);
                }
            },
            onError: (error) => {
                const errorMessage = error?.error || 'An error occurred while deleting the UserSpace.';
                setErrorMessage(errorMessage);
            }
        }
    );

    const postUserSpace = useMutation(
        async (obj) => await dynamicAPI('post', url, obj),
        {
            retry: 0,
            onSuccess: (data) => {
                if (data.isSuccess) {
                    setSuccessMessage('UserSpace created successfully.');
                    queryClient.invalidateQueries([queryKey]);
                } else {
                    const errorMessage = data.error || 'Failed to create UserSpace.';
                    setErrorMessage(errorMessage);
                }
            },
            onError: (error) => {
                const errorMessage = error?.error || 'An error occurred while creating the UserSpace.';
                setErrorMessage(errorMessage);
            }
        }
    );

    return {
        getUserSpaces,
        updateUserSpace,
        deleteUserSpace,
        postUserSpace,
        getUserSpaceById
    }
}
