import { Link, useNavigate } from 'react-router-dom';
import { FaSignInAlt, FaUser } from 'react-icons/fa';
import useAuthHook from '../../api/auth';
import useAuth from '../../hooks/useAuth';
import LanguageSwitcher from "./LanguageSwitcher";
import useUserCategoriesHook from '../../api/userCategories';
import { useMutation } from 'react-query';

const Navigation = () => {
    const navigate = useNavigate();
    const { postLogout } = useAuthHook();
    const { auth, setAuth } = useAuth();

    // Use the user categories hook to fetch categories
    const { getGuestCategories, getAdminCategories } = useUserCategoriesHook({});

    // Fetch appropriate categories based on auth state
    const { data: categoriesData, isLoading } = auth?.userInfo
        ? getAdminCategories
        : getGuestCategories;

    const { mutateAsync: logout } = useMutation(postLogout, {
        onSuccess: () => {
            setAuth(null);
            navigate('/admin/auth/login');
        },
    });

    const handleLogout = () => {
        logout({});
    };

    const user = () => {
        return auth?.userInfo;
    };

    const organizeMenus = () => {
        const categories = categoriesData?.categories || [];

        // Safely map categories, ensuring each item has a menu property
        const dropdownItems = categories.map((category) => category.menu || '');
        const uniqueDropdowns = [...new Set(dropdownItems.filter(item => item && item !== 'hidden' && item !== 'normal'))];

        // Separate profile item
        const profileDropdown = uniqueDropdowns.find(item => item === 'profile');
        const otherDropdowns = uniqueDropdowns.filter(item => item !== 'profile');

        // Organize other dropdowns and append profile at the end
        const sortedDropdowns = [...otherDropdowns, profileDropdown];

        const menuItems = categories.map((category) => category);

        return { sortedDropdowns, menuItems };
    };

    const renderAuthItems = () => {
        const { sortedDropdowns, menuItems } = organizeMenus();

        return (
            <ul className='navbar-nav ms-auto'>
                {menuItems.map((menu, index) => {
                    if (menu.menu === 'normal' && menu.auth === true) {
                        return (
                            <li key={menu._id || `menu-item-${index}`} className='nav-item'>
                                <Link to={menu.path} className='nav-link' aria-current='page'>
                                    {menu.name}
                                </Link>
                            </li>
                        );
                    } else {
                        return null;
                    }
                })}

                {sortedDropdowns.map((item, index) => (
                    item && (
                        <li key={`${item}-${index}`} className='nav-item dropdown'>
                            <a
                                className='nav-link dropdown-toggle'
                                id='navbarDropdownMenuLink'
                                role='button'
                                data-bs-toggle='dropdown'
                                aria-expanded='false'
                            >
                                {item === 'profile' ? (
                                    <>
                                        <FaUser className='me-1 menu-navigation-icon navigation-profile-icon' />
                                        {user()?.name}
                                    </>
                                ) : (
                                    item.charAt(0).toUpperCase() + item.substring(1)
                                )}
                            </a>
                            <ul className='dropdown-menu border-0' aria-labelledby='navbarDropdownMenuLink'>
                                {menuItems.map((menu) => {
                                    if (menu.menu === item) {
                                        return (
                                            <li key={menu._id}>
                                                <Link to={menu.path} className='dropdown-item'>
                                                    {menu.name}
                                                </Link>
                                            </li>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                                {item === 'profile' && (
                                    <li key={`profile-logout-${index}`}>
                                        <Link
                                            to='/admin/auth/login'
                                            className='dropdown-item logout-menu-item'
                                            onClick={handleLogout}
                                        >
                                            Logout
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </li>
                    )
                ))}
            </ul>
        );
    };

    const renderGuestItems = () => (
        <ul className='navbar-nav ms-auto'>
            <li key='guest-item-about' className='nav-item'>
                <Link to='/about' className='nav-link' aria-current='page'>About</Link>
            </li>
            <li key='guest-item-events' className='nav-item'>
                <Link to='/events' className='nav-link' aria-current='page'>Events</Link>
            </li>
            <li key='guest-item-spaces' className='nav-item'>
                <Link to='/spaces' className='nav-link' aria-current='page'>Spaces</Link>
            </li>

            <li key='guest-item-login' className='nav-item make-some-space'>
                <Link to='/admin/auth/login' className='nav-link' aria-current='page'>
                    <FaSignInAlt className='mb-1'/> Login
                </Link>
            </li>
        </ul>
    );

    if (isLoading) {
        return <div>Loading...</div>; // Loading state while fetching categories
    }

    // // Handle error state specifically for 401 Unauthorized
    // if (isError) {
    //     if (error?.status === 401) {
    //         return (
    //             <div>
    //                 {renderGuestItems()}
    //             </div>
    //         );
    //     }
    //     return <div>Error loading categories</div>;
    // }

    return (
        <nav className='navbar navbar-expand-md navbar-light bg-light'>
            <div className='container'>
                <Link to='/'>
                    <img
                        width='100'
                        height='100'
                        src='/img/artnet_logo_transparent_black.png'
                        className='img-fluid brand-logos'
                        alt='logo'
                    />
                </Link>
                <button
                    className='navbar-toggler'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#navbarNav'
                    aria-controls='navbarNav'
                    aria-expanded='false'
                    aria-label='Toggle navigation'
                >
                    <span className='navbar-toggler-icon'></span>
                </button>
                <div className='collapse navbar-collapse' id='navbarNav'>
                    {auth?.userInfo ? renderAuthItems() : renderGuestItems()}
                </div>
                <LanguageSwitcher />
            </div>
        </nav>
    );
};

export default Navigation;
