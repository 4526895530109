import React from 'react';
import { useNavigate } from 'react-router-dom';
import SpaceMap from "../../components/maps/space-map";
import SpaceForm from "../../components/maps/space-form";
import { Grid, Button } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";
import dynamicAPI from '../../api/dynamicAPI';
import { useQuery } from 'react-query';

function Maps() {
    const url = '/api/view/spaces';
    const q = '';
    const queryKey = 'Spaces';
    const { data: spacesData, error: spacesError } = useQuery(
        queryKey,
        async () =>
            await dynamicAPI('get', `${url}?page=1&q=${q}&limit=1000`, {}),
        { retry: 0 }
    );

    const navigate = useNavigate();

    // console.log(spacesData);


    return (
        <>
            <title>Mapping</title>
            <meta property='og:title' content='Mapping Artistic Spaces' key='title'/>

            <div className='map-block'>
                <ErrorBoundary
                    FallbackComponent={() => (
                        <ErrorFallback goBack={() => navigate(-1)} error={spacesError} />
                    )}
                    onError={console.error}
                >
                    <span className='map-section'>
                        <Grid gridRow={1} sx={{width: "100%", height: "100%"}}>
                            <Grid sm={12}>
                                <SpaceForm />
                            </Grid>
                            <Grid sm={12}>
                                {spacesData && <SpaceMap generatePins={spacesData.data}/>}
                            </Grid>
                        </Grid>
                    </span>
                </ErrorBoundary>
            </div>
        </>
    );
}

function ErrorFallback({ error, goBack }) {
    const errorMessage = error && error.message ? error.message : "Unknown error";

    return (
        <div>
            <div className="user-error-message-block">
                <p>Something went wrong 😭</p>
                <span>Error: {errorMessage}</span>
                <Button onClick={goBack}>Go Back</Button>
            </div>
        </div>
    );
}

export default Maps;