import {useNavigate, Link, Route, Routes} from 'react-router-dom'
import React from "react";
import {ErrorBoundary} from "react-error-boundary";
import {Button} from "@mui/material";
import EventsView from "./screens/frontend/Events";
import MapsView from "./screens/frontend/Maps";
// import Home from "./screens/frontend/Home";
import About from "./screens/frontend/About";
// import NotFound from "./screens/frontend/NotFound";
import {FrontendLayout} from './components';
import {AuthProvider} from './context/AuthProvider'
import {AdminMessageProvider} from './context/AdminMessageContext';
import './css/frontend.css'

const Frontend = () => {
    const navigate = useNavigate();
    return (
        <>
            <ErrorBoundary
                FallbackComponent={() => (
                    <ErrorFallback goBack={() => navigate(-1)}/>
                )}
                onError={console.error}
            >
                <AdminMessageProvider>

                <AuthProvider>

                {/*<ShoppingContextProvider>*/}
                <FrontendLayout showAdminNavigation={true}>

                    <Routes>
                        <Route path='/' element={<About/>}/>
                        <Route path='/about' element={<About/>}/>
                        <Route path='/events' element={<EventsView/>}/>
                        <Route path='/spaces' element={<MapsView/>}/>
                        <Route path="*" element={<About />} />
                    </Routes>
                </FrontendLayout>
            </AuthProvider>
            </AdminMessageProvider>

            {/*</ShoppingContextProvider>*/}

            </ErrorBoundary>
        </>
    )
}

    function ErrorFallback({error, goBack}) {
        const errorMessage = error && error.message ? error.message : "Unknown error";
        // const isDebugMode = process.env.NODE_ENV === 'development';
        // const errorStack = error && error.stack ? error.stack : "No stack trace";

        return (

            <div className="main-user-error-message-block">
                <div className="user-error-message-block">
                    <p>Something went wrong 😭</p>
                    <span>Error: {errorMessage}</span>
                    {/*{isDebugMode && (*/}
                    {/*    <>*/}
                    {/*        <p>Stack Trace:</p>*/}
                    {/*        <pre>{errorStack}</pre>*/}
                    {/*    </>*/}
                    {/*)}*/}
                    <p>-</p>
                    <p>
                        <Link to={"/"} className="shop_go_to_frontend_link">
                            GO TO FRONTEND
                        </Link>
                    </p>
                    <p>
                        <Button onClick={goBack}>Go Back</Button>
                    </p>
                </div>
            </div>
        );
    }

export default Frontend
