import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { confirmAlert } from 'react-confirm-alert';
import { useForm } from 'react-hook-form';
import useUserSpacesHook from '../../../api/userSpaces';
import ViewUserSpaces from '../../../components/admin/userSpaces/ViewUserSpaces';
import FormUserSpaces from '../../../components/admin/userSpaces/FormUserSpaces';
import useSimpleDataHook from '../../../api/simpleData';
import { Spinner, Pagination, Message, Confirm, Search } from '../../../components';

const UserSpaces = () => {
    const [page, setPage] = useState(1);
    const [id, setId] = useState(null);
    const [edit, setEdit] = useState(false);
    const [q, setQ] = useState('');
    const [selectedSpaces, setSelectedSpaces] = useState([]);

    const { getUserSpaces, postUserSpace, updateUserSpace, deleteUserSpace } = useUserSpacesHook({ page, q });
    const { getSimpleUsers, getSimpleSpaces } = useSimpleDataHook({ limit: 100000, page: 1 });

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors }
    } = useForm({
        defaultValues: {
            auth: true
        },
    });

    const { data, isLoading, isError, error, refetch } = getUserSpaces;
    const { data: dataUsers } = getSimpleUsers;
    const { data: dataSpaces } = getSimpleSpaces;

    const {
        isLoading: isLoadingUpdate,
        isSuccess: isSuccessUpdate,
        mutateAsync: mutateAsyncUpdate
    } = updateUserSpace;

    const {
        isLoading: isLoadingDelete,
        mutateAsync: mutateAsyncDelete
    } = deleteUserSpace;

    const {
        isLoading: isLoadingPost,
        isSuccess: isSuccessPost,
        mutateAsync: mutateAsyncPost
    } = postUserSpace;

    useEffect(() => {
        if (isSuccessPost || isSuccessUpdate) formCleanHandler();
    }, [isSuccessPost, isSuccessUpdate]);

    useEffect(() => {
        refetch();
    }, [page, q]);  // Single refetch for page and query change

    const searchHandler = (e) => {
        e.preventDefault();
        setPage(1);
        refetch();
    };

    const deleteHandler = (id) => {
        confirmAlert(Confirm(() => mutateAsyncDelete(id)));
    };

    const submitHandler = async (data) => {
        try {
            if (edit) {
                await mutateAsyncUpdate({
                    _id: id,
                    user: data.user,
                    spaces: data.spaces,
                });
            } else {
                await mutateAsyncPost(data);
            }
        } catch (error) {
            console.error("Submission Error:", error);
        }
    };

    const formCleanHandler = () => {
        reset();  // Reset form via react-hook-form
        setEdit(false);
        setId(null);
        setSelectedSpaces([]);
    };

    const editHandler = (userSpace) => {
        setId(userSpace._id);
        setEdit(true);

        // Set the user value in the form
        setValue('user', userSpace.user._id);

        // Map the spaces to the format expected by the Select component and set the value
        const spaces = userSpace.spaces.map(space => ({
            value: space._id,
            label: space.space_name
        }));
        setSelectedSpaces(spaces);
        setValue('spaces', spaces);
    };

    return (
        <HelmetProvider>
            <Helmet>
                <title>User Spaces</title>
            </Helmet>
            <div className='view-list-top-bar'>
                <h3 className='fw-light text-muted text-center mb-3'>
                    User Spaces List <sup className='fs-6'> [{data?.total || 0}] </sup>
                </h3>

                <div className='d-flex align-items-center justify-content-between mb-2'>
                    <button
                        className='add-new-entry-button'
                        data-bs-toggle='modal'
                        data-bs-target='#userSpaceModal'
                        onClick={formCleanHandler}
                    >
                        Add new User Space
                    </button>
                    <Pagination data={data} setPage={setPage} />
                </div>

                <div className='col-auto full-search'>
                    <Search
                        placeholder='Search by User / Space'
                        setQ={setQ}
                        q={q}
                        searchHandler={searchHandler}
                    />
                </div>
            </div>
            {isLoading ? (
                <Spinner />
            ) : isError ? (
                <Message variant="danger">{error}</Message>
            ) : (
                <ViewUserSpaces
                    data={data}
                    editHandler={editHandler}
                    deleteHandler={deleteHandler}
                    isLoadingDelete={isLoadingDelete}
                    formCleanHandler={formCleanHandler}
                />
            )}
            <div className='pagination-bottom-block'>
                <Pagination data={data} setPage={setPage} />
            </div>
            <FormUserSpaces
                edit={edit}
                formCleanHandler={formCleanHandler}
                isLoading={isLoading}
                isError={isError}
                errors={errors}
                isLoadingUpdate={isLoadingUpdate}
                isLoadingPost={isLoadingPost}
                register={register}
                handleSubmit={handleSubmit}
                submitHandler={submitHandler}
                dataUsers={dataUsers}
                dataSpaces={dataSpaces}
                setValue={setValue}
                selectedSpaces={selectedSpaces}
                setSelectedSpaces={setSelectedSpaces}
            />
        </HelmetProvider>
    );
};

export default UserSpaces;
